import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import ReactRevealText from 'react-reveal-text';
import Camera from 'react-html5-camera-photo';
import toArrayBuffer from 'to-array-buffer';
import { Heading, Box, Button } from 'grommet';
import AWS from '../lib/aws';
import CuckAlgorithm from '../lib/algorithm';

const LoadingBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
`;

const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
`;

const initialState = {
  finished: false,
  loading: false,
  cuck: false,
  takePhoto: false,
  error: false,
  imgPreview: '',
  text1: false,
  text2: false,
  text3: false,
};

const Error = styled(Box)`
  border-radius: 1rem;
  padding: 1rem;
`;

const ErrorText = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

class Upload extends React.Component {
  state = initialState;

  reset = () => {
    this.setState(initialState);
  };

  getFile = () => {
    return new Promise((resolve, reject) => {
      const preview = toArrayBuffer(this.state.imgPreview);

      if (preview) {
        resolve(preview);
        return;
      }

      const file = document.getElementById('imgupload').files[0];
      const reader = new FileReader();

      if (file) {
        reader.readAsArrayBuffer(file);
      }

      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'onerror',
        () => {
          reject();
        },
        false
      );
    });
  };

  tryToUpload = async () => {
    this.setState({ loading: true, finished: false });

    const file = await this.getFile();

    if (file) {
      const params = {
        Image: {
          Bytes: file,
        },
      };

      const { FaceMatches } = await this.searchCuckLibrary(params);

      if (FaceMatches.length > 0) {
        this.setState({ cuck: true });
        this.fadeInText();
        this.setState({ loading: false, finished: true });

        return;
      }

      // this.index(params);
      this.detectLabels(params);
    }
  };

  index = params => {
    const rekognition = new AWS.Rekognition();

    // var params = {
    //   CollectionId: 'CUCKS',
    // };
    // rekognition.createCollection(params, function(err, data) {
    //   if (err) console.log(err, err.stack);
    //   // an error occurred
    //   else console.log(data); // successful response
    //   /*
    //    data = {
    //     CollectionArn: "aws:rekognition:us-west-2:123456789012:collection/myphotos",
    //     StatusCode: 200
    //    }
    //    */
    // });

    rekognition.indexFaces(
      { ...params, CollectionId: 'CUCKS' },
      (err, data) => {
        if (err) console.log(err, err.stack);
        // an error occurred
        else console.log(data); // successful response
      }
    );
  };

  searchCuckLibrary = params => {
    const rekognition = new AWS.Rekognition();

    return new Promise((resolve, reject) => {
      rekognition.searchFacesByImage(
        { ...params, CollectionId: 'CUCKS' },
        (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        }
      );
    });
  };

  detectLabels = params => {
    const rekognition = new AWS.Rekognition();

    rekognition.detectLabels(params, (err, data) => {
      if (err) {
        this.setState({ error: true });
      } else {
        const cuck = CuckAlgorithm(data.Labels);
        this.setState({ cuck });
        this.fadeInText();
      }

      this.setState({ loading: false, finished: true });
    });
  };

  fadeInText = () => {
    setTimeout(() => {
      this.setState({ text1: true });
    }, 500);
    setTimeout(() => {
      this.setState({ text2: true });
    }, 1500);
    setTimeout(() => {
      this.setState({ text3: true });
    }, 2500);
  };

  onTakePhoto = uri => {
    this.setState({ imgPreview: uri });
  };

  showTakePhoto = () => {
    this.setState({ takePhoto: true });
  };

  retake = () => {
    this.setState({ imgPreview: '' });
  };

  use = () => {
    this.tryToUpload();
  };

  render() {
    const {
      cuck,
      loading,
      finished,
      takePhoto,
      imgPreview,
      error,
      text1,
      text2,
      text3,
    } = this.state;
    return (
      <React.Fragment>
        <Box direction="row" justify="between" width="300px">
          <input
            type="file"
            onChange={this.tryToUpload}
            style={{ display: 'none' }}
            id="imgupload"
          />
          <Button
            color="dark-1"
            label="Upload Photo"
            onClick={() => {
              document.getElementById('imgupload').click();
            }}
          />
          <Button
            color="dark-1"
            label="Take Photo"
            onClick={this.showTakePhoto}
          />
        </Box>
        {(finished || loading || takePhoto) && (
          <LoadingBackground>
            <Box align="center" justify="center" height="100%">
              {loading && (
                <React.Fragment>
                  <Heading color="white">Hang Tight</Heading>
                  <ReactLoading
                    type="cubes"
                    color="white"
                    height="10%"
                    width="10%"
                  />
                </React.Fragment>
              )}

              {cuck && finished && (
                <React.Fragment>
                  <ReactRevealText
                    show={text1}
                    style={{ color: 'white', fontSize: 40, fontWeight: 600 }}
                  >
                    Ugh Oh.. You are in fact the biggest cuck ever.
                  </ReactRevealText>

                  <ReactRevealText
                    style={{ color: 'white', fontSize: 30, marginTop: 30 }}
                    show={text2}
                    delayMin={1500}
                  >
                    Sorry....
                  </ReactRevealText>

                  <ReactRevealText
                    style={{ color: 'white', fontSize: 30, marginTop: 30 }}
                    show={text3}
                    delayMin={2500}
                  >
                    Actually no we aren't.
                  </ReactRevealText>
                </React.Fragment>
              )}

              {!cuck && finished && (
                <React.Fragment>
                  <ReactRevealText
                    show={text1}
                    style={{ color: 'white', fontSize: 40, fontWeight: 600 }}
                  >
                    Look at You
                  </ReactRevealText>

                  <ReactRevealText
                    show={text2}
                    delayMin={1500}
                    style={{ color: 'white', fontSize: 30, marginTop: 30 }}
                  >
                    You are not a cuck.
                  </ReactRevealText>

                  <ReactRevealText
                    show={text3}
                    delayMin={2500}
                    style={{ color: 'white', fontSize: 30, marginTop: 30 }}
                  >
                    Congratulations.
                  </ReactRevealText>
                </React.Fragment>
              )}

              {!imgPreview && !loading && !finished && (
                <Camera
                  onTakePhoto={dataUri => {
                    this.onTakePhoto(dataUri);
                  }}
                />
              )}

              {imgPreview && !loading && !finished && (
                <Box>
                  <img src={imgPreview} alt="preview" />
                  <Box direction="row" justify="between">
                    <Button
                      style={{ color: 'white' }}
                      label="Use"
                      color="light-1"
                      onClick={this.use}
                    />
                    <Button
                      style={{ color: 'white' }}
                      label="Re-Take"
                      color="light-1"
                      onClick={this.retake}
                    />
                  </Box>
                </Box>
              )}

              {error && (
                <Error
                  width="80%"
                  height="60px"
                  margin="3rem"
                  background="status-error"
                >
                  <ErrorText>
                    There was an error with your photo. Please try another one!
                  </ErrorText>
                </Error>
              )}
            </Box>
            <Close>
              <Button
                label="Close"
                style={{ color: 'white' }}
                color="light-1"
                onClick={this.reset}
              />
            </Close>
          </LoadingBackground>
        )}
      </React.Fragment>
    );
  }
}

export default Upload;
