import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Grommet } from 'grommet';

import './layout.css';
import 'react-html5-camera-photo/build/css/index.css';

const theme = {
  global: {
    font: {
      family: 'Work Sans',
      size: '14px',
      height: '20px',
    },
    colors: {
      primary: '#464646',
    },
  },
};

const Layout = ({ children }) => (
  <Grommet theme={theme} full>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
            }}
          >
            {children}
          </div>
        </>
      )}
    />
  </Grommet>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
