const ALPHA_TRAITS = ['Human', 'Person', 'Face', 'Man', 'Beard'];

export default labels => {
  let noCuck = true;
  const presentTraits = labels.map(l => l.Name);

  ALPHA_TRAITS.forEach(t => {
    noCuck = presentTraits.includes(t);
  });

  return !noCuck;
};
