import React from 'react';
import { Heading, Paragraph, Box } from 'grommet';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import Upload from '../components/upload';

const IndexPage = () => (
  <Layout>
    <SEO title="CuckOrNot" keywords={['cuck', 'dumbass']} />

    <Box direction="row">
      <Box>
        <Box width="400px">
          <Image />
        </Box>

        <Box>
          <Heading level={3}>
            Are you struggling to understand if you are a cuck or not? Have your
            friends called you a cuck? Do you not consider youself an alpha?
          </Heading>

          <Heading level={4}>
            You may in fact be a cuck. However if you want to know for sure
            upload your picture and we will let you know.
          </Heading>

          <Paragraph>
            Be warned if you are in fact a cuck. You will be displayed forever
            on our wall of shame. Choose wisely what you do next.
          </Paragraph>
          <Upload />
        </Box>
      </Box>
    </Box>
  </Layout>
);

export default IndexPage;
